/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* colors*/
.colors {
  --green: var(#14B8A6);
  --black: var(	#334155);
  --white: var(#E2E8F0);
}